<template>
	<div>
		<el-card 
			class="box-card" 
			@click='toCourseDetail(item)'
			shadow="hover">
			<div class='combo-img'>
				<img :src="item.combo[0].thumb" alt="">
			</div>
			<div class='combo-info'>
				<h3 class='course-title'>{{item.title}}</h3>
				<div class='course-tag' v-if='item.service'>
					<el-tag
						:type="$config.buttonType"
						class='tag-item'
						:key='sindex'
						v-for="(sitem,sindex) in item.service.split('/')">
						{{sitem}}
					</el-tag>
				</div>
				<div class='course-pay'>
					<div class='pay-num'>{{item.num}}人报名</div>
					<div class='pay-price' v-if='item.combo'>
						<div class='price'>
							￥<span class='num'>{{item.combo[0].sale_price}}</span>
						</div>
						<span>起</span>
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {setSessionStorage} from '@/utils/auth'
	export default{
		name:'comboclassItem',
		props:['item'],
		data(){
			return{
				
			}
		},
		created() {
			// console.log(this.item);
		},
		methods:{
			toCourseDetail(item){//跳转课程详情
				if(item.combo.length>0){
					let defaultCombo = item.combo[0];
					this.$router.push({
						path:'/coursedetail/'+item.spec_id+'/'+item.id + '/'+defaultCombo.id
					})
				}
				
			}
		}
	}
</script>

<style scoped lang="scss">
	.box-card{
		height:265px;
		padding:0px!important;
		box-sizing: border-box;
		transition-property:all;
		transition-duration:0.5s;
		margin-bottom: 15px;
		border-radius:5px;
		.combo-img{
			
		}
		.combo-info{
			padding:0px 10px;
			display: flex;
			flex-direction: column;
			.course-title{
				width: 100%;
				color:$text-color;
				overflow: hidden;
				height: 30px;
				line-height: 30px;
				
				overflow: hidden;
				text-overflow:ellipsis;
				display: inline-block;
				white-space: nowrap;
			}
			.course-tag{
				margin:8px 0px;
				font-size:12px;
				.tag-item{
					margin-right:5px;
				}
			}
			.course-pay{
				display: flex;
				flex-direction:row;
				align-items: center;
				justify-content: space-between;
				.pay-num{
					color:$info-color;
					font-size:12px;
				}
				.pay-price{
					display: flex;
					flex-direction:row;
					align-items: center;
					color:$info-color;
					font-size:12px;
					.price{
						color:$price-color;
						.num{
							font-size:16px;
						}
					}
				}
			}
		}
	}
	.box-card:hover{
		box-shadow:0px 0px 3px 3px #ccc;
	}
	.box-card:last{
		margin-right:0px;
	}
</style>
