<template>
  <div>
	<!-- 头部导航 -->
    <el-affix :z-index='2001'>
      <nav-bar></nav-bar>
    </el-affix>
	<!-- 工具栏 -->
	<tool-box></tool-box>

	<el-card class='box-card swiper-top'>
		<!-- 广告轮播加课程类型菜单 -->
		<div class='swiper-wrap container' @mouseleave="hideSubtitle()">
			<div class='swiper' >
				<swiper
					:slides-per-view="1"
					:space-between="0"
					:autoplay="{delay:3000,disableOnInteraction:false}"
					loop
					:pagination="{clickable:true}">
					<swiper-slide
						@click='adInfo(item)' 
						v-for='(item,index) in bannerList' 
						:key='index'>
						<img :src="item.thumb" alt="" :title="item.title">
					</swiper-slide>
				</swiper>
			</div>
			<div class='menus-swiper'>
			    <ul class='menus'>
					<li
						class='menus-list'
						:key='index'
						@mouseenter="showSubtitle(item)"
						v-for="(item,index) in menusList">
						<div class='list-item'>
							<div class="title">{{item.title}} :</div>
							<div class='sub-title'>
								<span 
									v-for="(subItem,sIndex) in item.children"
									:key='sIndex'>{{subItem.title}} / </span>
							</div>
							<div class='sub-icon'>
								<i class="el-icon-caret-right"></i>
							</div>
						</div>
					</li>
			    </ul>
				<!-- 定位内容层 -->
				<div 
					class='sub-content' 
					v-show='subtitleFlag'
					v-if='currentSubList'>
					<h3 class='title'>{{currentSubList.title}}</h3>
					<div class='content'>
						<ul>
							<li 
								class='tag-list'
								@click='toCourseList(item)'
								v-for="(item,index) in currentSubList.children"
								:key="index">
								{{item.title}}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<!-- 广告 -->
		<div class='course-ad container'>
			<el-row :gutter="5">
				<el-col
					v-show='index > 1'
					:span="6"
					v-for="(item,index) in adImages"
					:key='index'>
					<div @click='adInfo(item)' >
						<img
						   class="ad-image"
						   :src="item.thumb"
						   :alt="item.title"
						   :style="{height:'100%',width:'100%'}"/>
					</div>
				</el-col>
			</el-row>
		</div>
	</el-card>
	
	<!-- 课程内容 -->
	<div 
		class='recomment-wrap container'
		v-for='(item,index) in recommentList' 
		:key='index'>
		<div class='recomment-title'>
			<div class='title-list'>
				<div class='title-icon'>
					<svg-icon icon-class='rec-hot' class='hot-icon'></svg-icon>
				</div>
				<div class='title first-spec'>{{item.title}}</div>
				<div class='sub-title'>
					<ul 
						class='sub-item' 
						v-for='(citem,cindex) in item.children' 
						:key='cindex'>
						<li 
							class='second-spec'
							:class="citem.checked?'secondSpecActive':''"
							@click="onCourseSpec(item,index,citem)">
							{{citem.title}}
						</li>
					</ul>
				</div>
			</div>
			<div class='more' @click='moreCourseList(item)'>
				<span>更多</span>
				<svg-icon icon-class='youbian' class='more-icon'></svg-icon>
			</div>
		</div>
		<!-- 内容 -->
		<div class='recomment-content'>
			<div v-if='currentComboSpec[index].content'>
				<div class ='content-course' 
					v-loading='currentComboSpec[index].loading'>
					<div class='course-list'>
						<el-row :gutter="15">
							<el-col 
								:span='6'
								v-for='(bitem,bindex) in currentComboSpec[index].content.comboclass' 
								:key='bindex'
							>
								<comboclass-item :item='bitem'></comboclass-item>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
			<div v-else v-loading='true' class='content-course'></div>
		</div>
	</div>
	<!-- footer -->
	<web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import { ElNotification } from 'element-plus';
import SwiperCore, { 
		Navigation, 
		Pagination, 
		Scrollbar, 
		A11y, 
		EffectFade, 
		Autoplay } from 'swiper';
import {Swiper,SwiperSlide} from 'swiper/vue'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]);	

import NavBar from '@/components/page/NavBar'
import ToolBox from '@/components/toolbox'
import WebFooter from '@/components/page/WebFooter'

import comboclassItem from '@/views/course/components/ComboClassItem2'

import {getSessionStorage,setSessionStorage} from '@/utils/auth'
import {mapState,mapMutations} from 'vuex';

import {
		getBanner,
		getMenus,
		getProSpec,
		getComboBySpec,
		getComboClass,
		getAdvertSpec
	} from '@/api/index2'

export default {
  data() {
    return {
		bannerList:[],
		subtitleFlag:false,
		currentSubList:{},
		adImages:[],
		menusList:[],
		recommentList:[],
		currentYear:'',
		currentImg:[],//当前封面
		currentComboSpec:[],//当前班型
		currentNews:[],//当前资讯
		currentNewsActive:0,//当前选中新闻
    }
  },
  components: {
    NavBar,
    ToolBox,
	Swiper,
	SwiperSlide,
	WebFooter,
	comboclassItem
  },
  computed:{
    ...mapState(['token']),
  },
  created(){
  	getBanner().then(res=>{//获取轮播图
  	  if(res.code==1){
  	    this.bannerList = res.data;
  	  }
  	});
	getMenus().then(res=>{//获取导航菜单
	  if(res.code==1){
	    this.menusList = res.data;
	  }
	});
	//获取广告
	getAdvertSpec().then(res=>{
	  if(res.code==1){
	    this.adImages = res.data;
	  }
	});
	getProSpec().then(res=>{  //行业分类，考试专题
	  if(res.code==1){
			this.recommentList = res.data;
			res.data.map((item,index)=>{
				item.children.map((citem,cindex)=>{
					if(cindex==0){
						citem.checked = true;
						this.currentImg.push(citem);
						this.currentComboSpec.push(citem);
					}else{
						citem.checked = false;
					}
				})
			});

			this.currentComboSpec.map((item,index)=>{
				getComboBySpec({
					spec_id:item.id
				}).then(res=>{
					if(res.code==1){
						let comboclass = res.data.comboclass.filter((fitem,findex)=>{
							return fitem.status==1
						});
						res.data.comboclass = comboclass;
						item.content = res.data;
					}
				});
			});
	  }
	});
	this.registerWindowResize();
  },
  methods:{
	adInfo(list){//轮播图广告跳转
		if(list.type == 1){//海报
	  
		}
		if(list.type == 2){//课程列表
			let idArr = list.urlId.split(",");
			this.$router.push({
			  path:'/course/' + idArr[0] + '/'+idArr[1]
			});
		}
	},
	toCourseList(item){//menue课程列表
		this.$router.push({
			path:'/course/'+item.pid+'/'+item.id
		})
	},
	moreCourseList(item){//更多
		let curChildren = item.children;
		curChildren.map((item,index)=>{
			if(item.checked){
				this.$router.push({
					path:'/course/'+item.pid+'/'+item.id
				})
			}
		})
	},
	showSubtitle(item){
		this.subtitleFlag = true;
		this.currentSubList = item;
	},
	hideSubtitle(item){
		this.subtitleFlag = false;
		this.currentSubList = {};
	},
	onCourseSpec(fitem,findex,citem){
		this.recommentList.map((item,index)=>{
			if(fitem.id==item.id){
				item.children.map((sitem,sindex)=>{
					if(sitem.id==citem.id){
						sitem.checked = true;
						//切换封面
						this.currentImg[findex] = sitem;
						//切换数据
						getComboBySpec({
							spec_id:sitem.id
						}).then(res=>{
							if(res.code==1){
								let comboclass = res.data.comboclass.filter((fitem,findex)=>{
									return fitem.status==1
								});
								res.data.comboclass = comboclass;
								this.currentComboSpec[index].content = res.data;
								this.currentComboSpec[index].loading = true;
								let _this = this;
								setTimeout(function(){
									_this.currentComboSpec[index].loading = false;
								},350)
								
							}
						});
					}else{
						sitem.checked = false;
					}
				})
			}
		})
	},
	toMoreExam(){
		this.$router.push('/exam')
	},
	toSpecialPage(item){
		this.$router.push('/special/' + item.id)
	},
	toQuestion(item){//跳转做题
		if(this.token){
			setSessionStorage('currentPaper',item);
			this.$router.push({
				path:'/question'
			});
		}else{
			this.$notify.error({
			  title: '未登录',
			  message: '请先登录'
			});
			return;
		}
	},
	toNewsDetail(nitem){//跳转新闻详情
		this.$router.push({
			path:'/newsdetail/'+ nitem.article_id
		});
	},
	toNewsList(item){//跳转新闻列表
		let curChildren = item.children;
		curChildren.map((item,index)=>{
			if(item.checked){
				setSessionStorage('newsSpecialId',item.id)
				this.$router.push({
					path:'/news'
				});
			}
		})
	},
	toExamList(specId, childId, type) {//跳转题库
	  setSessionStorage('examListParams', {
	    specId: specId || this.recommentList[0].id,
	    specChildId: childId || this.recommentList[0].children[0].id,
	    type: type || 7,
	  })
	  this.$router.push('/examlist')
	},
	registerWindowResize(){
	  this.screenWidth = document.body.clientWidth;
	  this.screenHeight = document.body.clientHeight;
	  window.onresize = () => {
	    return (() => {
	      if(document.body.clientWidth > 1920){
	        this.screenWidth = 1920
	      }else if(document.body.clientWidth < 1200){
	        this.screenWidth = 1200
	      }else{
	        this.screenWidth = document.body.clientWidth;
	      }
	      this.screenHeight = document.body.clientHeight;
	    })();
	  };
	}
  }
}
</script>

<style>
	.swiper-pagination-clickable .swiper-pagination-bullet{
		width:40px;
		height:10px;
		border-radius: 10px;
	}
	.swiper-pagination-bullet-active{
		background: #fff;
	}
		
</style>
<style scoped lang="scss">
	.swiper-top{
		background:linear-gradient(to bottom ,$theme-color 1% ,#fff 55%);
	}
	//轮播图
	.swiper-wrap{
		height:350px;
		overflow: hidden;
		box-sizing: border-box;
		position: relative;
		margin-top:20px;
		display: flex;
		flex-direction: column;
	   .swiper{
		   height:350px;
		   width:100%;
		   overflow: hidden;
		   box-sizing: border-box;
		   cursor: pointer;
		}
	   .menus-swiper{
			position: absolute;
			left:0px;
			top:0px;
			z-index: 99;
			height: 350px;
			overflow: hidden;
			box-sizing: border-box;
			
			display: flex;
			flex-direction: row;
		 .menus{
		   width:300px;
		   height:350px;
		   overflow: hidden;
		   box-sizing: border-box;
		   padding:10px 10px;
		   background:#333;
		   color:#fff;
		   display: flex;
		   flex-direction: column;
		   .menus-list{
					flex:1;
					display: flex;
					flex-direction: row;
					align-items: center;
			   .list-item{
					display: flex;
					flex-direction: row;
					align-items: center;
					box-sizing: border-box;
					overflow: hidden;
				   .title{
				     font-size:16px;
				     cursor: pointer;
				     width:90px;
				     overflow: hidden;
				     box-sizing: border-box;
				   }
				   .sub-title{
						width:200px;
						box-sizing: border-box;
						
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						span{
							font-size:12px;
							cursor: pointer;
						}
				   }
				   .sub-icon{
						margin-left:5px;
				   }
				}
		   }
		   .menus-list:hover{
			   color:$theme-color;
		   }
		 }
		 // 定位内容层
		.sub-content{
		   background:rgba(255,255,255,0.8);
		   overflow: hidden;
		   box-sizing: border-box;
		   width:700px;
		   height: 350px;
		   box-sizing: border-box;
		   padding:30px 20px;
		   .title{
			   font-weight: bold;
			   margin-bottom:15px;
		   }
		   .content{
			   display: flex;
			   flex-direction: row;
			   align-items: center;
			   ul{
				   display: flex;
				   flex-direction: row;
				   align-items: center;
				   flex-wrap: wrap;
				   li{
					   padding:5px 15px;
					   margin-right:10px;
					   margin-bottom: 10px;
					   cursor: pointer;
					   background-color:#E8E8E8;
					   border:1px solid #E8E8E8;
				   }
				   li:hover{
					   background-color:$theme-color;
					   border:1px solid $theme-color;
					   color:#fff;
				   }
			   }
		   }
		}
		 
	   }
	}
	.course-ad{
		margin-top:5px;
		margin-bottom:20px;
	}

	// 课程分类
	.recomment-wrap{
		margin-top:30px;
		.recomment-title{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			height:80px;
			line-height: 80px;
			box-sizing: border-box;
			overflow: hidden;
			.title-list{
				display: flex;
				flex-direction: row;
				align-items: center;
				.title-icon{
					font-size:24px;
					font-weight: bold;
					margin-right:8px;
					.hot-icon{
						color:#f40
					}
				}
				.title{
					font-size:20px;
					font-weight: bold;
					height: 100%;
					font-style: n;
				}
				.sub-title{
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size:16px;
					height: 100%;
					margin-left:20px;
					ul{
						height: 100%;
						margin-bottom:2px;
						li{
							height:50px;
							line-height: 50px;
							margin:0px 15px;
							cursor: pointer;
							overflow: hidden;
							box-sizing: border-box;
						}
						li:hover{
							border-bottom: 2px solid $theme-color;
							color:$theme-color;
						}
						li.secondSpecActive{
							border-bottom: 2px solid $theme-color;
							color:$theme-color;
						}
					}
				}
			}
			.more{
				height: 100%;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				align-items: center;
				color:$info-color;
				.more-icon{
					margin-left:5px;
				}
			}
			.more:hover{
				color:$theme-color;
			}
		}
		.recomment-content{
			margin-top:15px;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			.content-course{
				flex:1;
				height: 100%;
				display: flex;
				flex-direction: row;
				.course-list{
					height: 100%;
					display: flex;
					flex-direction:column;
					.course-combo{
						width:100%;
					}
				}
			}
		}
	}
	
	// 题库
	.paper-wrap{
		margin-top:50px;
		margin-bottom:50px;
		.paper-title{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			height:60px;
			line-height: 60px;
			box-sizing: border-box;
			overflow: hidden;
			border-bottom:1px solid #ccc;
			.title-list{
				display: flex;
				flex-direction: row;
				align-items: center;
				.title{
					font-size:24px;
					font-weight: bold;
					height: 100%;	
				}
			}
			.more{
				height: 100%;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				align-items: center;
				color:$info-color;
				.more-icon{
					margin-left:5px;
				}
			}
			.more:hover{
				color:$theme-color;
			}
		}
		.paper-con{
			width:100%;
			margin-top:15px;
			height: 370px;
			display: flex;
			flex-direction: row;
			ul{
				display: flex;
				flex-direction: row;
				height: 100%;
				li{
					flex:1;
					margin-right:15px;
					position: relative;
					height: 100%;
					overflow: hidden;
					cursor: pointer;
					img{
						height: 100%;
					}
					.paper-mask{
						position: absolute;
						left:0px;
						top:0px;
						right:0px;
						bottom:0px;
						background:rgba(0,0,0,0.4);
						color:#fff;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.paper-item-top{
							display: flex;
							flex-direction: column;
							margin-top:10px;
							.paper-item-icon{
								width:80px;
								height: 80px;
								text-align: center;
								line-height: 80px;
								border-radius: 50%;
								margin:20px auto;
								background-color:rgba(255,255,255);
								font-size:45px;
								
								transition-property:all;
								transition-duration:1s;
								
								.paper-moni{
									color:$success-color;
								}
								.paper-lianxi{
									color:$danger-color
								}
								.paper-miya{
									color:$primary-color;
								}
								.paper-chapter{
									color:$warning-color;
								}
								.paper-true{
									color:#FF33CC;
								}
							}
							.paper-item-title{
								font-size:20px;
								text-align: center;
								font-weight:500;
							}
							.paper-item-text{
								padding:30px;
								text-align: center;
							}
						}
						.paper-item-doc{
							height:60px;	
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
							color:#fff;
						}
					}
				}
				li:last-child{
					margin-right:0px;
				}
				li:hover .paper-item-icon{
					transform:rotateY(360deg);
					-ms-transform:rotateY(360deg);	/* IE 9 */
					-moz-transform:rotateY(360deg); 	/* Firefox */
					-webkit-transform:rotateY(360deg); /* Safari 和 Chrome */
					-o-transform:rotateY(360deg);
				}
			}
		}
	}
</style>